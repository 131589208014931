import { formInputs, FormProvider, useLocalFormState } from '@moller/design-system';
import { ValidatorMap } from '@moller/design-system/utilities/validation';
import parse from 'html-react-parser';
import Link from 'next/link';
import React, { Fragment, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Submit from './buttons/Submit';
import headers from './config/headers';
import Date from './fields/Date';
import Select from './fields/select/Select';
import Time from './fields/Time';
import SMSVerification from './SMSVerification';
import { FormState } from './types/FormState';
import IFormInput from './types/IFormInput';
import IFormSchema from './types/IFormSchemaJSON';
import submitForm from './utils/submitForm';
import NoPrefetchLink from '../NoPrefetchLink';

const { TextField, TextArea } = formInputs<FormState>();

interface IFormContent {
  formTitle: string;
  initialState: FormState;
  validator: ValidatorMap<IFormInput>;
  formName: string;
  formSchemaJSON: IFormSchema;
  setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  dealerId?: string;
}

const HTMLElement = styled.span`
  ${({ theme }) => css`
    a {
      color: ${theme.ColorPrimary};
      text-decoration: underline;
    }
  `}
`;

const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    display: flex;
    align-self: flex-end;
  }
`;

const SubmitError = styled.span`
  ${({ theme }) => css`
    display: flex;
    color: ${theme.ColorError};

    a {
      font-weight: 500;
      text-decoration: underline;
    }
  `}
`;

function FormContent({
  formTitle,
  initialState,
  validator,
  formName,
  formSchemaJSON,
  setCompleted,
  dealerId,
}: IFormContent) {
  const form = useLocalFormState(initialState, validator);
  const [regNrError, setRegNrError] = useState(false);
  const [OTPFieldEnabled, setOTPFieldEnabled] = useState(false);
  const [OTP, setOTP] = useState('0000');
  const [OTPValid, setOTPValid] = useState(true);
  const [submitError, setSubmitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitAttemt, setSubmitAttemt] = useState(false);

  const dateFieldRef = useRef(null);
  const timeFieldRef = useRef(null);

  const handleSubmit = (data: FormState) => {
    setSubmitError(false);
    setIsLoading(true);

    submitForm(
      JSON.stringify({ data }),
      headers,
      formName,
      formTitle,
      OTP,
      setRegNrError,
      setCompleted,
      setOTPValid,
      setSubmitError,
      setIsLoading,
    );
  };

  return (
    <FormProvider
      id={formSchemaJSON.name}
      form={form}
      onSubmit={(data) => {
        handleSubmit(data);
      }}
      hideNecessityText
      name={formSchemaJSON.name}
    >
      <div className="grid gap-y-3">
        {formSchemaJSON &&
          formSchemaJSON.components.map((component) => {
            if (component.type === 'textfield') {
              if (component.properties?.validator === 'regNr') {
                return (
                  <TextField
                    key={component.key}
                    field={component.key}
                    label={component.label}
                    validity={
                      regNrError
                        ? {
                            type: 'error',
                            message: 'Modellen eksisterer ikke',
                          }
                        : {
                            type: 'none',
                          }
                    }
                  />
                );
              }
              if (component.key === 'cellPhoneNumber') {
                return (
                  <Fragment key={component.key}>
                    <TextField field={component.key} label={component.label} prefix="+47" />
                    {component.validate?.required &&
                      component.properties?.smsvalidation === 'true' && (
                        <SMSVerification
                          phoneNumber={form?.state?.validated?.cellPhoneNumber}
                          setOTPFieldEnabled={setOTPFieldEnabled}
                          setOTP={setOTP}
                          OTPValid={OTPValid}
                          setOTPValid={setOTPValid}
                          OTPFieldEnabled={OTPFieldEnabled}
                        />
                      )}
                  </Fragment>
                );
              }

              return (
                <TextField field={component.key} label={component.label} key={component.key} />
              );
            }
            if (component.type === 'button') {
              return (
                <SubmitWrapper key={component.key}>
                  {submitError && (
                    <SubmitError>
                      {parse(
                        '<p>Beklager, noe gikk galt. Vennligst prøv igjen senere eller kontakt kundeservice på e-post <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a></p>',
                      )}
                    </SubmitError>
                  )}
                  <p>
                    Ved å sende inn skjemaet godtar du våre retningslinjer for personvern. Les mer{' '}
                    <NoPrefetchLink
                      href="/personvern"
                      target="_blank"
                      className="decoration-moller-color-primary text-moller-color-primary underline underline-offset-2 hover:decoration-transparent"
                    >
                      her
                    </NoPrefetchLink>
                    .
                  </p>
                  <Submit
                    label={component.label}
                    isLoading={isLoading}
                    onClick={() => setSubmitAttemt(true)}
                  />
                </SubmitWrapper>
              );
            }
            if (component.type === 'textarea') {
              return <TextArea field={component.key} label={component.label} key={component.key} />;
            }
            if (component.type === 'datetime') {
              return (
                <Date
                  componentKey={component.key}
                  label={component.label}
                  key={component.key}
                  inputRef={dateFieldRef}
                  required={component.validate?.required}
                />
              );
            }
            if (component.type === 'time') {
              return (
                <Time
                  componentKey={component.key}
                  label={component.label}
                  key={component.key}
                  inputRef={timeFieldRef}
                  required={component.validate?.required}
                />
              );
            }
            if (component.type === 'select') {
              const hasBrand = formSchemaJSON.components.find((c) => c.properties?.brand);

              return (
                <Select
                  placeholder={component.label}
                  component={component}
                  key={component.key}
                  form={form}
                  hasBrand={hasBrand?.properties?.brand}
                  dealerId={dealerId}
                  submitAttemt={submitAttemt}
                />
              );
            }
            if (
              component.type === 'htmlelement' &&
              component.properties?.subtitle !== 'true' &&
              component.properties?.title !== 'true'
            ) {
              return (
                <HTMLElement key={component.key}>{parse(component?.content || '')}</HTMLElement>
              );
            }

            return null;
          })}
      </div>
    </FormProvider>
  );
}

export default FormContent;
