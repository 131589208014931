/* eslint-disable no-nested-ternary */
import { useForm } from '@moller/design-system';
import styled, { css } from 'styled-components';
import { FormState } from '../types/FormState';
import { ComponentKeyLabel } from '../types/IFormSchemaJSON';

import { ErrorIcon, ErrorMessage, RequiredLabel } from './select/Select';

export const InputWrapper = styled.span<{ hasValue: boolean }>`
  ${(props) => css`
    ${({ theme }) => css`
      display: flex;
      flex-direction: column;
      margin: 8px 0 22px;
      position: relative;

      input {
        width: 100%;
        color: ${props.hasValue ? theme.ColorDarkBackground : theme.PaletteNeutral50};
      }

      &:focus-within {
        label:first-of-type {
          color: ${theme.ColorDarkBackground};
        }
      }
    `}
  `}
`;

export const Required = styled(RequiredLabel)`
  bottom: -22px;
`;

export const Error = styled(ErrorMessage)`
  bottom: -22px;
`;

function Date({ componentKey, label, inputRef, required }: ComponentKeyLabel & any) {
  const { form } = useForm<FormState>()!;
  const inputProps = form.inputProps(componentKey);
  const showFieldError = required && form.state.showError[componentKey];

  return (
    <InputWrapper hasValue={!!inputProps.value}>
      <p>{label}</p>
      <input
        type="date"
        id={componentKey}
        name={label}
        placeholder={label}
        className="border-gray rounded border pb-2 pl-3 pr-2 pt-2 outline outline-0 hover:border-black"
        onChange={(event) => {
          inputProps.setValue(event.target.value);
        }}
        ref={inputRef}
        style={{
          borderColor: showFieldError ? '#d82c0d' : '#747474',
        }}
      />
      {showFieldError ? (
        <Error>
          <ErrorIcon icon="error" />
          Vennligst fyll ut dette feltet
        </Error>
      ) : required ? (
        <Required>Må fylles ut</Required>
      ) : (
        ''
      )}
    </InputWrapper>
  );
}

export default Date;
