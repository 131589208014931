import { Icon, LabelText } from '@moller/design-system';
import { useEffect, useState, ChangeEvent } from 'react';

import { LocalFormState } from '@moller/design-system/utilities/forms/useLocalFormState';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import fetchSanityData from '../../../../utils/fetchSanityData';
import { FormState } from '../../types/FormState';
import styles from './index.module.scss';
import { FormComponent } from '../../types/IFormSchemaJSON';

interface SelectProps {
  component: FormComponent;
  form: LocalFormState<FormState>;
  dealerId?: string;
  hasBrand?: string;
  placeholder: string;
  submitAttemt?: boolean;
}

type OptionType = {
  label: string;
  value: string;
};

enum OptionsProperty {
  MODEL = 'carmodels',
  DEALER = 'dealerships',
}

export const RequiredLabel = styled(LabelText)`
  ${({ theme }) => css`
    color: ${theme.PaletteNeutral50};

    font-size: ${theme.TypefaceSizeXs}px;
  `}
`;

export const ErrorMessage = styled(LabelText)`
  ${({ theme }) => css`
    color: #c92d2d;
    display: flex;
    align-items: center;
    font-size: ${theme.TypefaceSizeXs}px;
  `}
`;

export const ErrorIcon = styled(Icon)`
  ${({ theme }) => css`
    color: #c92d2d;
    height: ${theme.SizeXxs};
    width: ${theme.SizeXxs};
    padding-right: ${theme.SpacingBase}px;
    svg {
      height: 12px;
    }
  `}
`;

const getCarBrandFromFormBrand = (formBrand: string) => {
  switch (formBrand) {
    case 'audi':
      return 'audi';
    case 'skoda':
      return 'skoda';
    case 'vwperson':
      return 'volkswagen';
    case 'vwnytte':
      return 'volkswagen-nyttekjoretoy';
    default:
      return '';
  }
};
function Select({ component, form, dealerId, hasBrand, placeholder, submitAttemt }: SelectProps) {
  const router = useRouter();
  const inputProps = form?.inputProps(component.key);
  const [options, setOptions] = useState<OptionType[]>([]);

  const showFieldError = () => {
    if (submitAttemt && inputProps.value === '') {
      return (
        <ErrorMessage>
          <ErrorIcon name="error" icon="error" />
          Vennligst velg et gyldig alternativ.
        </ErrorMessage>
      );
    }
    return null;
  };

  const matchedDealer = options?.find((opt) => {
    return opt.value === router.query.dealerId || opt.value === dealerId;
  });

  const isModelForm =
    component.properties?.options && component.properties.options === OptionsProperty.MODEL;

  const isDealerForm =
    component.properties?.options && component.properties.options === OptionsProperty.DEALER;

  useEffect(() => {
    if (component.data?.values) {
      setOptions(component.data?.values);
      return;
    }

    if (isModelForm) {
      const fetchSanityCarModels = async () => {
        const modelParams = {
          carBrand: getCarBrandFromFormBrand(component.properties?.brand || ''),
        };

        const models: OptionType[] = await fetchSanityData('modelsForm', modelParams);

        let filteredModels;
        if (component.properties?.brand === 'vwperson') {
          filteredModels = models.filter(
            (model) => model.value !== '100000020' && model.value !== '100000008',
          );
        }

        if (component.properties?.brand === 'vwnytte') {
          models.push({
            label: 'Volkswagen ID.Buzz',
            value: '100000020',
          });
          models.push({
            label: 'Volkswagen Multivan eHybrid',
            value: '100000008',
          });
        }

        setOptions(
          filteredModels ||
            models.sort((a: { label: string }, b: { label: string }) => {
              return a.label.localeCompare(b.label, 'nb-NO');
            }),
        );
      };
      fetchSanityCarModels();
    }

    if (isDealerForm) {
      const fetchSanityDealers = async () => {
        let dealers: OptionType[] = [];

        if (hasBrand && hasBrand !== 'all') {
          dealers = await fetchSanityData('dealerForm', {
            carBrand: getCarBrandFromFormBrand(hasBrand),
          });
        } else {
          dealers = await fetchSanityData('dealerFormContact');
        }
        setOptions(dealers);
      };
      fetchSanityDealers();
    }
  }, [component.data?.values, component.properties?.brand, hasBrand, isDealerForm, isModelForm]);

  const handleOnChange = (selectValue: string) => {
    if (selectValue) {
      inputProps.setValue(selectValue);
    } else {
      inputProps.setValue('default');
    }
  };

  useEffect(() => {
    if (matchedDealer) {
      inputProps.setValue(matchedDealer.value);
    }
  }, [inputProps, matchedDealer]);

  return (
    <div className={styles.selectContainer}>
      <select
        className={styles.select}
        value={inputProps.value || 'default'}
        disabled={!!matchedDealer?.value}
        onChange={(e: ChangeEvent<HTMLSelectElement>) => handleOnChange(e.target.value)}
      >
        <option value="default">
          {placeholder === 'Bilmodell' ? 'Velg bilmodell' : placeholder}
        </option>
        {options &&
          options.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>

      {/* Conditionally render error message if the value is still 'default' */}
      {showFieldError()}
    </div>
  );
}

export default Select;
