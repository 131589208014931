import { formInputs, SecondaryButton } from '@moller/design-system';
import React from 'react';
import styled, { css } from 'styled-components';
import headers from './config/headers';
import { FormState } from './types/FormState';
import verifyPhoneNumber from './utils/verifyPhoneNumber';

interface ISMSVerification {
  phoneNumber: string;
  setOTPFieldEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  setOTPValid: React.Dispatch<React.SetStateAction<boolean>>;
  setOTP: React.Dispatch<React.SetStateAction<string>>;
  OTPValid: boolean;
  OTPFieldEnabled: boolean;
}

const { TextField } = formInputs<FormState>();

const SMSWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin-top: -${theme.SpacingXs}px;
    column-gap: ${theme.SpacingXxs}px;

    button {
      margin: ${theme.SpacingXxs}px 0 0 0;
      display: flex;
    }

    > span {
      min-width: fit-content;
      display: flex;
      flex-direction: column;
    }
  `}
`;

function SMSVerification({
  phoneNumber,
  setOTPFieldEnabled,
  OTPValid,
  OTPFieldEnabled,
  setOTP,
  setOTPValid,
}: ISMSVerification) {
  return (
    <>
      <p>Vi sender deg en engangskode på SMS for å verifisere ditt telefonnummer.</p>
      <SMSWrapper>
        <SecondaryButton
          disabled={!phoneNumber}
          key="OTPButton"
          onClick={() => {
            setOTPFieldEnabled(true);
            verifyPhoneNumber(
              JSON.stringify({
                phoneNumber,
              }),
              headers,
            );
            setTimeout(() => document.getElementById('OTP')?.focus(), 200);
          }}
        >
          Send kode på SMS
        </SecondaryButton>
        <TextField
          autoFocus
          label="Engangskode"
          key="OTP"
          disabled={!OTPFieldEnabled || !phoneNumber}
          onChange={(event) => {
            setOTPValid(true);
            setOTP(event.target.value);
          }}
          field=""
          required
          validity={
            OTPValid
              ? {
                  type: 'none',
                }
              : {
                  type: 'error',
                  message: 'Engangskoden er feil',
                }
          }
        />
      </SMSWrapper>
    </>
  );
}

export default SMSVerification;
