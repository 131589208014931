import { ImSpinner8 } from 'react-icons/im';
import cn from '../../../utils/cn';
import { buttonVariants } from '../../Button';

interface SubmitProps {
  label: string;
  isLoading: boolean;
  onClick: () => void;
}

function Submit({ label, isLoading, onClick }: SubmitProps) {
  return (
    <button
      type="submit"
      className={cn(
        buttonVariants({ variant: 'primary' }),
        isLoading ? 'pointer-events-none cursor-not-allowed bg-[#d9dce1]' : '',
      )}
      onClick={onClick}
    >
      {!isLoading ? label : <ImSpinner8 className="animate-spin text-[#8e94a0]" />}
    </button>
  );
}

export default Submit;
